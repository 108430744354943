// TODO: At some point we should probably bring Redux in to manage this
// However, for the time being a simple singleton implementation should be ok
// Any data that we want to be easily accessible from anywhere in the application can be stored here
import {User} from '../types/User';

export class WebState {

    private static instance: WebState;

    private user: User | null;

    private constructor() {
        this.user = null;
    }

    public static getInstance() {
        if (!WebState.instance) {
            WebState.instance = new WebState();
        }
        return WebState.instance;
    }

    public setUser(user: User) {
        this.user = user;
    }

    public getUser() {
        return this.user;
    }

}