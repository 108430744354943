import {Journey} from '../types/Journey';
import {DayData} from '../types/DayData';

export const formatDateString = (date: Date | undefined | null) => {
    if(!date) {
        return undefined;
    }
    let dateString = date.toISOString();
    dateString = dateString.split('T')[0];
    return dateString ?? '';
};

export const anyDailyFrequencySet = (journey: Journey): boolean => {
    return journey.mondayStartTime !== '00:00:00' ||
        journey.mondayEndTime !== '00:00:00' ||
        journey.tuesdayStartTime !== '00:00:00' ||
        journey.tuesdayEndTime !== '00:00:00' ||
        journey.wednesdayStartTime !== '00:00:00' ||
        journey.wednesdayEndTime !== '00:00:00' ||
        journey.thursdayStartTime !== '00:00:00' ||
        journey.thursdayEndTime !== '00:00:00' ||
        journey.fridayStartTime !== '00:00:00' ||
        journey.fridayEndTime !== '00:00:00' ||
        journey.saturdayStartTime !== '00:00:00' ||
        journey.saturdayEndTime !== '00:00:00' ||
        journey.sundayStartTime !== '00:00:00' ||
        journey.sundayEndTime !== '00:00:00';
};

export const getDayDataFromJourney = (journey: Journey): DayData[] => {
    return [
        {dayName: 'SUNDAY', active: journey.sundayStartTime !== '00:00:00' || journey.sundayEndTime !== '00:00:00'},
        {dayName: 'MONDAY', active: journey.mondayStartTime !== '00:00:00' || journey.mondayEndTime !== '00:00:00'},
        {dayName: 'TUESDAY', active: journey.tuesdayStartTime !== '00:00:00' || journey.tuesdayEndTime !== '00:00:00'},
        {dayName: 'WEDNESDAY', active: journey.wednesdayStartTime !== '00:00:00' || journey.wednesdayEndTime !== '00:00:00'},
        {dayName: 'THURSDAY', active: journey.thursdayStartTime !== '00:00:00' || journey.thursdayEndTime !== '00:00:00'},
        {dayName: 'FRIDAY', active: journey.fridayStartTime !== '00:00:00' || journey.fridayEndTime !== '00:00:00'},
        {dayName: 'SATURDAY', active: journey.saturdayStartTime !== '00:00:00' || journey.saturdayEndTime !== '00:00:00'}
    ];
};