import {Button, CloseButton, Col, Container, Form, FormControl, FormSelect, Row} from 'react-bootstrap';
import {Trigger} from '../../types/Trigger';
import {ChangeEvent} from 'react';
import {formatDateString} from '../../util/dateUtils';

interface Props {
    triggers: Trigger[];
    addTriggerCallback: () => void;
    removeTriggerCallback: (index: number) => void;
    triggerChangeCallback: (index: number, newTrigger: Trigger) => void;
}

export const TriggersView = (props: Props) => {

    const handleTimeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const triggerToChange = props.triggers.at(index);
        if (triggerToChange) {
            triggerToChange.time = parseInt(event.target.value);
            props.triggerChangeCallback(index, triggerToChange);
        }
    };

    const handleUnitChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const triggerToChange = props.triggers.at(index);
        if (triggerToChange) {
            triggerToChange.timeUnit = event.target.value as 'days' | 'hours' | 'weeks' | 'months';
            props.triggerChangeCallback(index, triggerToChange);
        }
    };

    const handleAfterChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const triggerToChange = props.triggers.at(index);
        if (triggerToChange) {
            triggerToChange.after = event.target.value === 'after';
            props.triggerChangeCallback(index, triggerToChange);
        }
    };

    const handleOnDateChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const triggerToChange = props.triggers.at(index);
        if (triggerToChange) {
            triggerToChange.onDate = new Date(event.target.value);
            props.triggerChangeCallback(index, triggerToChange);
        }
    };

    const handleDateTriggerChange = (index: number) => {
        const triggerToChange = props.triggers.at(index);
        let newTrigger: Trigger;
        if (triggerToChange && !triggerToChange.onDate) {
            newTrigger = {
                onDate: new Date(),
                after: undefined, api: undefined, time: undefined, timeUnit: undefined
            };
        }
        else {
            newTrigger = {
                timeUnit: 'days',
                time: 30,
                after: true,
                api: 'placementStart',
                onDate: undefined
            };
        }
        props.triggerChangeCallback(index, newTrigger);
    };

    const handleApiTokenChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const triggerToChange = props.triggers.at(index);
        if (triggerToChange) {
            triggerToChange.api = event.target.value as 'placementEnd' | 'placementStart';
            props.triggerChangeCallback(index, triggerToChange);
        }
    };

    return (
        <Container fluid>
            <Row>
                <Col md={'auto'}>
                    <Row className={'trigger-rows'}>
                        <label>Triggers</label>
                    </Row>
                    {props.triggers.length === 0 ? <span>This Journey currently has no triggers.</span> : <></>}
                    {props.triggers.map((t, index) => (
                        <div style={{display: 'flex'}}>
                            <Form>
                                <Row md={'auto'} style={{marginBottom: '15px'}}>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        <CloseButton onClick={() => props.removeTriggerCallback(index)}/>
                                    </Col>
                                    {t.onDate ?
                                        <>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <span>Trigger on date:</span>
                                            </Col>
                                            <Col>
                                                <FormControl
                                                    onChange={(e) => handleOnDateChange(e, index)}
                                                    type={'date'}
                                                    as={'input'}
                                                    value={formatDateString(t.onDate)}
                                                />
                                            </Col>
                                            <Button onClick={() => handleDateTriggerChange(index)} variant={'link'}>Trigger based on an API value</Button>
                                        </>
                                        :
                                        <>
                                            <Col>
                                                <FormControl
                                                    onChange={(e) => handleTimeChange(e, index)}
                                                    as={'input'}
                                                    type={'number'}
                                                    value={t.time}
                                                    style={{maxWidth:'130px'}}/>
                                            </Col>
                                            <Col>
                                                <FormSelect
                                                    onChange={(e) => handleUnitChange(e, index)}
                                                    value={t.timeUnit}>
                                                    <option value={'days'}>Days</option>
                                                    <option value={'hours'}>Hours</option>
                                                    <option value={'weeks'}>Weeks</option>
                                                    <option value={'months'}>Months</option>
                                                </FormSelect>
                                            </Col>
                                            <Col>
                                                <FormSelect
                                                    onChange={(e) => handleAfterChange(e, index)}
                                                    value={t.after ? 'after' : 'before'}>
                                                    <option value={'after'}>After</option>
                                                    <option value={'before'}>Before</option>
                                                </FormSelect>
                                            </Col>
                                            <Col>
                                                <FormSelect onChange={(e) => handleApiTokenChange(e, index)} value={t.api}>
                                                    <option value={'placementStart'}>Start Date</option>
                                                    <option value={'placementEnd'}>End Date</option>
                                                </FormSelect>
                                            </Col>
                                            <Button onClick={() => handleDateTriggerChange(index)} variant={'link'}>Trigger on a specific date</Button>
                                        </>
                                    }
                                </Row>
                            </Form>
                        </div>
                    ))}
                    <Row md={'auto'} className={'trigger-rows'}>
                        <span>
                            <Button size={'sm'} variant={'success'} onClick={props.addTriggerCallback}>Add new trigger</Button>
                        </span>
                    </Row>
                    <Row className={'trigger-rows'}>
                        <small>Note: The message will be sent when conditions for ANY of the above triggers are met.</small>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};