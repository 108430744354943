import {Button, Col, Container, Image, NavDropdown, Row} from 'react-bootstrap';
import logo_compact from '../../logo_compact.svg';
import react_logo from '../../logo.svg';

import './header.css';
import {useEffect, useState} from 'react';
import {AccountInfo} from '@azure/msal-browser';
import {MSALData} from '../../util/MSALData';
import {Link, useNavigate} from 'react-router-dom';
import {appRoles} from '../../util/authConfig';

const Header = () => {

    const [userAccount, setUserAccount] = useState<AccountInfo>();
    const navigate = useNavigate();

    useEffect(() => {
        const activeAccount = MSALData.getInstance().getMsalInstance().getActiveAccount();

        if (activeAccount) {
            setUserAccount(activeAccount);
        }
    }, []);

    return (
        <Container className="header-border" fluid>
            <Row className="align-items-center justify-content-between" style={{height:90, width: '100%'}}>
                <Col md={'auto'}>
                    <Link to={'/'}>
                        <Image src={logo_compact}/>
                    </Link>
                </Col>
                <Col md={'auto'}>
                    <Container className={'header-menu'}>
                        <Row>
                            <Col>Billing</Col>
                            <Col>Quality</Col>
                            <Col>Recruitment</Col>
                            <Col>
                                <NavDropdown title={'Marketing'}>
                                    <NavDropdown.Item>
                                        <Link className={'nav-dropdown-link'} to={'/sms'}>SMS Journeys</Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Col>
                            <Col>IT</Col>
                        </Row>
                    </Container>
                </Col>
                <Col md={'auto'}>
                    <Row className="align-items-center">
                        {MSALData.getInstance().getUserRoles().includes(appRoles.Admin) ?
                            <Button className={'admin-button'} variant={'link'} onClick={() => navigate('/admin')}>
                                <Col style={{minWidth:155}}>
                                    {userAccount ? <span>Welcome, {userAccount.name}!</span> : <span>Welcome!</span>}
                                </Col>
                                <Col>
                                    <Image roundedCircle width={50} height={50} src={react_logo}/>
                                </Col>
                            </Button>
                            :
                            <>
                                <Col style={{minWidth:155}}>
                                    {userAccount ? <span>Welcome, {userAccount.name}!</span> : <span>Welcome!</span>}
                                </Col>
                                <Col>
                                    <Image roundedCircle width={50} height={50} src={react_logo}/>
                                </Col>
                            </>
                        }
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;