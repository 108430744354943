import {useEffect, useState} from 'react';
import {User} from '../../types/User';
import {fetchUsers} from '../../api';
import {Container, Table} from 'react-bootstrap';
import {UserListItem} from './UserListItem';
import './AdminPanel.css';
import {OverlayToast} from '../OverlayToast/OverlayToast';

export const AdminPanel = () => {
    
    const [userList, setUserList] = useState<User[]>();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showFailure, setShowFailure] = useState(false);

    useEffect(() => {
        const fetchUserList = async () => {

            const response = await fetchUsers();

            if (response.status === 200) {
                setUserList(response.data);
            }

        };
        
        fetchUserList();

    }, []);

    const showToast = (variant: 'success' | 'danger') => {
        if (variant === 'success') {
            setShowSuccess(true);
            window.setTimeout(() => setShowSuccess(false), 5000);
        }
        if (variant === 'danger') {
            setShowFailure(true);
            window.setTimeout(() => setShowFailure(false), 5000);
        }
    };

    return (
        <Container>
            <h1 style={{textAlign: 'center'}}>User List</h1>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Active Directory Username</th>
                    <th>Role</th>
                </tr>
                </thead>
                <tbody>
                {userList?.map((u: User) => (
                    <tr>
                        <UserListItem key={u.userId} user={u} showToastCallback={showToast} />
                    </tr>
                ))}
                </tbody>
            </Table>
            <OverlayToast show={showSuccess} headerText={'Success!'} bodyText={'User was successfully updated.'} bg={'success'}/>
            <OverlayToast show={showFailure} headerText={'Failure'} bodyText={'Something went wrong.'} bg={'danger'}/>
      </Container>
    );

};