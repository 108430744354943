import {Col, Container, FormSelect, Row} from 'react-bootstrap';
import {ChangeEvent, useState} from 'react';
import {APIDataCard} from './APIDataCard';
import {APIData} from '../../types/APIData';
import {salesForceCandidateData, salesforceJobData} from '../../util/constants';

interface Props {
    copyTokenCallback: (token: string) => void;
}

export const APIsView = (props: Props) => {

    const [selectedAPI, setSelectedAPI] = useState('select');
    const [apiData, setAPIData] = useState<APIData[]>();


    const handleAPIChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedAPI(event.target.value);
        switch (event.target.value) {
            case 'SFCandidate':
                setAPIData(salesForceCandidateData);
                break;
            case 'SFPlacement':
                setAPIData(salesforceJobData);
                break;
            default:
                setAPIData(undefined);
                break;
        }
    };

    return (
        <Container fluid>
            <Row xs={'auto'}>
                <Col>
                    <label>APIs</label>
                    <FormSelect onChange={handleAPIChange} value={selectedAPI}>
                        <option value={'select'}>Select an API to view information</option>
                        <option value={'SFCandidate'}>SalesForce - Candidate</option>
                        <option value={'SFPlacement'}>Salesforce - Placement</option>
                    </FormSelect>
                </Col>
            </Row>
            <Row>
                {apiData ? <p style={{fontSize: '11px', margin: '10px'}}>*Use the token to include data in the Email/SMS
                    body.</p> : <></>}
            </Row>
            <Row xs={'auto'}>
                {apiData?.map((apiData) => (
                    <Col>
                        <APIDataCard name={apiData.name} tokenString={apiData.tokenString} copyTokenCallback={props.copyTokenCallback}/>
                    </Col>
                ))}
            </Row>
        </Container>
    );

};