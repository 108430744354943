import {FormControl} from 'react-bootstrap';
import {ChangeEvent, useEffect, useRef, useState} from 'react';

interface Props {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    value: string;
    type?: string;
    minWidth?: number;
    maxWidth?: number;
}

export const DynamicWidthInput = (props: Props) => {

    const widthMachine = useRef<HTMLSpanElement>(null);
    const [inputWidth, setInputWidth] = useState<number>();

    useEffect(() => {
        if (widthMachine.current) {
            if (props.minWidth && props.minWidth >= widthMachine.current.offsetWidth) {
                setInputWidth(props.minWidth + 26);
                return;
            }
            if (props.maxWidth && props.maxWidth <= widthMachine.current.offsetWidth) {
                setInputWidth(props.maxWidth + 26);
                return;
            }
            setInputWidth(widthMachine.current.offsetWidth + 26);
        }
    }, [props.minWidth, props.maxWidth, props.value]);



    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div>
            <FormControl
                onChange={handleChange}
                type={props.type ? props.type : 'text'}
                as={'input'}
                value={props.value}
                role={'textbox'}
                style={{width: inputWidth}}
            />
            <span ref={widthMachine} style={{position:'absolute', opacity:0, pointerEvents:'none'}}>{props.value}</span>
        </div>
    );
};