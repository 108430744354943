import './SmsView.css';

import {Button} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {changeJourneyStatus, createJourney, fetchJourneys, deleteJourney} from '../../api';
import {Journey} from '../../types/Journey';

import {useNavigate} from 'react-router-dom';
import {ButtonsBar} from './ButtonsBar';
import {Circle} from '../Circle/Circle';
import {OverlayToast} from '../OverlayToast/OverlayToast';
import {HorizontallyCenteredSpinner} from '../util/HorizontallyCenteredSpinner';
import {JourneyButtonLoading} from '../../types/JourneyButtonLoading';
import {useMsal} from '@azure/msal-react';

const getMessageCount = (messages: []) => {
    if (messages == null) {
        return 0;
    } else {
        return messages.length;
    }
};

interface Props {
    journeyCreationSuccess: boolean;
    journeyUpdateSuccess:  boolean;
}

export const SmsView = (props: Props) => {

    const {instance} = useMsal();
    const userAccount = instance.getActiveAccount();
    
    const [journeys, setJourneys] = useState<Journey[]>();

    const [loading, setLoading] = useState<boolean>(true);
    const [buttonLoadingData, setButtonLoadingData] = useState<JourneyButtonLoading[]>([]);

    // toasts
    const [showLoadingError, setShowLoadingError] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchJourneysAsync().catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setShowLoadingError(true);
            setLoading(false);
            window.setTimeout(() => setShowLoadingError(false), 5000);
        });
    }, []);

    const fetchJourneysAsync = async () => {
        setLoading(true);
        const response = await fetchJourneys();
        if (response.status === 200) {
            let journeys = response.data as Journey[];
            setJourneys(journeys);
            setLoading(false);

            const newData = [];
            for (const journey of journeys) {
                const buttonLoading: JourneyButtonLoading = {
                    guid: journey.journeyGuid,
                    loadingPause: false,
                    loadingClone: false,
                    loadingDelete: false
                };
                newData.push(buttonLoading);
            }
            setButtonLoadingData(newData);
        }
    };

    const handleStatusChange = (guid: string) => {
        const newData = [...buttonLoadingData];
        const dataToChange = newData.find(d => d.guid === guid);
        dataToChange!.loadingPause = true;
        setButtonLoadingData(newData);

        const changeStatus = async () => {
            try {
                const response = await changeJourneyStatus(guid);
                if (response.status === 204) {

                    const newJourneys = [...journeys!];
                    for (const newJourney of newJourneys) {
                        if (newJourney.journeyGuid === guid) {
                            newJourney.status = newJourney.status === 'Active' ? 'Paused' : 'Active';
                            break;
                        }
                    }

                    setJourneys(newJourneys);

                    setShowSuccess(true);
                    window.setTimeout(() => setShowSuccess(false), 5000);
                    const newData = [...buttonLoadingData];
                    const dataToChange = newData.find(d => d.guid === guid);
                    dataToChange!.loadingPause = false;
                    setButtonLoadingData(newData);
                }
            } catch (err) {
                setShowLoadingError(true);
                window.setTimeout(() => setShowLoadingError(false), 5000);
                const newData = [...buttonLoadingData];
                const dataToChange = newData.find(d => d.guid === guid);
                dataToChange!.loadingPause = false;
                setButtonLoadingData(newData);
            }
        };

        changeStatus();
    };

    const handleCloneJourney = (guid: string) => {
        const newData = [...buttonLoadingData];
        const dataToChange = newData.find(d => d.guid === guid);
        dataToChange!.loadingClone = true;
        setButtonLoadingData(newData);

        const cloneJourney = async () => {
            const journeyToClone = journeys?.find(j => j.journeyGuid === guid);
            if (journeyToClone) {
                const newJourney = {...journeyToClone};
                newJourney.journeyId = 0;
                newJourney.journeyGuid = '';
                newJourney.journeyName = `${journeyToClone.journeyName} [CLONE]`;
                newJourney.creationDate = new Date();
                newJourney.status = 'Paused';

                const authorUserName = userAccount?.name ?? '';
                const authorEntraUserName = userAccount?.username ?? '';
                
                newJourney.journeyAuthor = {
                    name: authorUserName,
                    entraUsername: authorEntraUserName,
                    role: 'SmsRead'
                };

                try {
                    const response = await createJourney(newJourney);

                    if (response.status === 204) {
                        try {
                            fetchJourneysAsync();
                        } catch (err) {
                            setShowLoadingError(true);
                            window.setTimeout(() => setShowLoadingError(false), 5000);
                            const newData = [...buttonLoadingData];
                            const dataToChange = newData.find(d => d.guid === guid);
                            dataToChange!.loadingClone = false;
                            setButtonLoadingData(newData);
                        }

                        setShowSuccess(true);
                        window.setTimeout(() => setShowSuccess(false), 5000);
                        const newData = [...buttonLoadingData];
                        const dataToChange = newData.find(d => d.guid === guid);
                        dataToChange!.loadingClone = false;
                        setButtonLoadingData(newData);
                    }
                } catch (err) {
                    setShowLoadingError(true);
                    window.setTimeout(() => setShowLoadingError(false), 5000);
                    const newData = [...buttonLoadingData];
                    const dataToChange = newData.find(d => d.guid === guid);
                    dataToChange!.loadingClone = false;
                    setButtonLoadingData(newData);
                }
            }

        };

        cloneJourney();

    };

    const handleDeleteJourney = (guid: string) => {
        const newData = [...buttonLoadingData];
        const dataToChange = newData.find(d => d.guid === guid);
        dataToChange!.loadingDelete = true;
        setButtonLoadingData(newData);

        const deleteJourneyAsync = async () => {
            try {
                const response = await deleteJourney(guid);
                if (response.status === 204) {
                    try {
                        fetchJourneysAsync();
                    } catch (err) {
                        setShowLoadingError(true);
                        window.setTimeout(() => setShowLoadingError(false), 5000);
                        const newData = [...buttonLoadingData];
                        const dataToChange = newData.find(d => d.guid === guid);
                        dataToChange!.loadingDelete = false;
                        setButtonLoadingData(newData);
                    }

                    setShowDeleteSuccess(true);
                    window.setTimeout(() => setShowDeleteSuccess(false), 5000);
                    const newData = [...buttonLoadingData];
                    const dataToChange = newData.find(d => d.guid === guid);
                    dataToChange!.loadingDelete = false;
                    setButtonLoadingData(newData);
                }
            } catch (err) {
                setShowLoadingError(true);
                window.setTimeout(() => setShowLoadingError(false), 5000);
                const newData = [...buttonLoadingData];
                const dataToChange = newData.find(d => d.guid === guid);
                dataToChange!.loadingDelete = false;
                setButtonLoadingData(newData);
            }
        };

        deleteJourneyAsync();
    };

    return (
        <div id="sms-container">
            <div style={{display: 'flex', flex: '1', gap: '10px'}}>
                <h1 className='marketingHeader'>Marketing | SMS Journeys</h1>
                <Button className="reportButton" onClick={() => navigate('reports/')}>REPORTS</Button>
                <Button className="addButton" onClick={() => navigate('journey/')}>ADD</Button>
            </div>
            {
                loading ?
                    <HorizontallyCenteredSpinner showLoadingText={true}/>
                    :
                    <>
                        <table>
                            <tbody>
                            <tr>
                                <th>Journey Name</th>
                                <th>Journey Author</th>
                                <th>Status</th>
                                <th>Creation Date</th>
                                <th>Total Sent</th>
                                <th>Actions</th>
                            </tr>
                            {
                                journeys?.map((journey) => (
                                    <tr>
                                        <td>{journey.journeyName}</td>
                                        <td>{journey.journeyAuthor.name}</td>
                                        <td><Circle color={journey.status === 'Active' ? '#7FB549' : 'red'}
                                                    diameter="20px"/></td>
                                        <td>{journey.creationDate.toDateString()}</td>
                                        <td>{getMessageCount(journey.messages)}</td>
                                        <td><ButtonsBar
                                            journeyGuid={journey.journeyGuid}
                                            journey={journey}
                                            loadingClone={buttonLoadingData.find(b => b.guid === journey.journeyGuid)?.loadingClone!}
                                            loadingPause={buttonLoadingData.find(b => b.guid === journey.journeyGuid)?.loadingPause!}
                                            loadingDelete={buttonLoadingData.find(b => b.guid === journey.journeyGuid)?.loadingDelete!}
                                            updateStatusCallback={handleStatusChange}
                                            cloneJourneyCallback={handleCloneJourney}
                                            deleteJourneyCallback={handleDeleteJourney}
                                        /></td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        <div className="rectangle"></div>
                    </>
            }
            <OverlayToast
                show={showLoadingError}
                headerText={'Error!'}
                bodyText={'Something went wrong!'}
                bg={'danger'}
            />
            <OverlayToast
                show={props.journeyCreationSuccess}
                headerText={'Success!'}
                bodyText={'New Journey successfully created.'}
                bg={'success'}
            />
            <OverlayToast
                show={showSuccess || props.journeyUpdateSuccess}
                headerText={'Success!'}
                bodyText={'Successfully updated the journey.'}
                bg={'success'}
            />
            <OverlayToast
                show={showDeleteSuccess}
                headerText={'Success!'}
                bodyText={'Successfully deleted the journey.'}
                bg={'success'}
            />
        </div>
    );
};