import {Button, CloseButton, Col, Container, Form, FormControl, FormSelect, Row} from 'react-bootstrap';
import {RecipientCondition} from '../../types/RecipientCondition';
import {ChangeEvent} from 'react';
import {formatDateString} from '../../util/dateUtils';
import {DynamicWidthInput} from '../util/DynamicWidthInput';

const LastBox = ({condition, handleApiValueChange, idx}: {condition: RecipientCondition; handleApiValueChange: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>, index: number) => any; idx: number}) => {

    if (condition.apiName === undefined) {
        return null;
    }
    
    return (
        <>
            {['candidateFirstName','candidateStatus', 'tempType', 'extensionRecruiterStage', 'status'].includes(condition.apiName) ?
                <DynamicWidthInput
                    value={condition.apiValue as string}
                    minWidth={194}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleApiValueChange(e, idx)}
                />
                :
                <></>
            }
            {['placementStart', 'placementEnd'].includes(condition.apiName) ?
                <FormControl
                    onChange={(e) => handleApiValueChange(e, idx)}
                    type={'date'}
                    as={'input'}
                    value={formatDateString(condition.apiValue as Date)}
                />
                :
                <></>
            }
            {['extension', 'terminated'].includes(condition.apiName) ?
                <FormSelect
                    onChange={(e) => handleApiValueChange(e, idx)}
                    value={condition.apiValue === true ? 'true' : 'false'}
                >
                    <option value={'true'}>True</option>
                    <option value={'false'}>False</option>
                </FormSelect>
                :
                <></>
            }
            {['numberOfWeeksBooked', 'numberOfPlacements', 'tempAddressLastUpdate'].includes(condition.apiName) ?
                <FormControl
                    onChange={(e) => handleApiValueChange(e, idx)}
                    type={'number'}
                    as={'input'}
                    value={condition.apiValue !== 'null' ? condition.apiValue as string : ''}
                />
                :
                <></>
            }
        </>
    );
};

interface Props {
    conditions: RecipientCondition[];
    addConditionCallback: () => void;
    removeConditionCallback: (index: number) => void;
    conditionChangeCallback: (index: number, conditionToChange: RecipientCondition) => void;
    submitAttempted: boolean;
}

export const ConditionsView = (props: Props) => {

    const handleApiChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {
            const acceptableApiNames = [
                'candidateFirstName',
                'placementStart',
                'placementEnd',
                'tempType',
                'candidateStatus',
                'extensionRecruiterStage',
                'extension',
                'terminated',
                'status',
                'numberOfWeeksBooked',
                'numberOfPlacements',
                'tempAddressLastUpdate'];
            
            if (acceptableApiNames.includes(event.target.value)) {
                conditionToChange.apiName = event.target.value as 'candidateFirstName' | 'placementStart' | 'placementEnd' | 'tempType' | 'candidateStatus' | 'extensionRecruiterStage' | 'extension' | 'terminated'
                    | 'status' | 'numberOfWeeksBooked' | 'numberOfPlacements' | 'tempAddressLastUpdate' | undefined; // TODO: gross
            }
            else {
                conditionToChange.apiName = undefined;
            }
            
            switch (conditionToChange.apiName) {
                case 'candidateStatus':
                case 'tempType':
                case 'extensionRecruiterStage':
                case 'status':
                case 'candidateFirstName':
                    conditionToChange.apiValue = '' as string;
                    break;
                case 'placementStart':
                case 'placementEnd':
                    conditionToChange.apiValue = new Date(new Date().toDateString()) as Date;
                    break;
                case 'extension':
                case 'terminated':
                    conditionToChange.apiValue = true;
                    break;
                case 'numberOfWeeksBooked':
                case 'numberOfPlacements':
                case 'tempAddressLastUpdate':
                    conditionToChange.apiValue = 0;
            }

            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    const handleComparatorChange = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {
            conditionToChange.apiComparator = event.target.value as '<' | '<=' | '=' | '>' | '>=' | '!=';
            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    const handleApiValueChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>,
                                  index: number) => {
        const conditionToChange = props.conditions.at(index);
        if (conditionToChange) {
            switch (conditionToChange.apiName) {
                case 'candidateStatus':
                case 'tempType':
                case 'extensionRecruiterStage':
                case 'status':
                case 'candidateFirstName':
                    conditionToChange.apiValue = event.target.value as string;
                    break;
                case 'placementStart':
                case 'placementEnd':
                    conditionToChange.apiValue = new Date(event.target.value) as Date;
                    break;
                case 'extension':
                case 'terminated':
                    conditionToChange.apiValue = (event.target.value === 'true') as boolean;
                    break;
                case 'numberOfWeeksBooked':
                case 'numberOfPlacements':
                case 'tempAddressLastUpdate':
                    if ((event.target.value as string) === '') {
                        conditionToChange.apiValue = 'null';
                    }
                    else {
                        conditionToChange.apiValue = parseInt(event.target.value) as number;
                    }
            }

            props.conditionChangeCallback(index, conditionToChange);
        }
    };

    return (
        <Container fluid>
            <Row md={'auto'}>
                <Col md={'auto'}  style={{width: '100%'}}>
                    <Row className={'trigger-rows'}>
                        <label>Recipient Selection Conditions</label>
                    </Row>
                    {props.conditions.length === 0 ? <span>This Journey currently has no conditions.</span> : <></>}
                    {props.conditions.map((c, index) => (
                        <div style={{display: 'flex'}}>
                            <Form  style={{width: '100%'}}>
                                <Row md={'auto'} style={{marginBottom: '15px'}}>
                                    <Col style={{display: 'flex', alignItems: 'center'}}>
                                        <CloseButton onClick={() => props.removeConditionCallback(index)}/>
                                    </Col>
                                    <Col>
                                        <FormSelect
                                            onChange={(e) => handleApiChange(e, index)}
                                            value={c.apiName}
                                            isInvalid={c.apiName === undefined && props.submitAttempted}
                                        >
                                            <option value={'undefined'}>Select a Condition</option>
                                            <optgroup label={'Salesforce - Candidate'}>
                                                <option value={'candidateFirstName'}>First Name</option>
                                                <option value={'numberOfPlacements'}>Number of Placements</option>
                                                <option value={'candidateStatus'}>Candidate Status</option>
                                                <option value={'tempAddressLastUpdate'}>Temp Address Last Update</option>
                                                <option value={'tempType'}>Temp Type</option>
                                            </optgroup>
                                            <optgroup label={'Salesforce - Placement'}>
                                                <option value={'placementStart'}>Start Date</option>
                                                <option value={'placementEnd'}>End Date</option>
                                                <option value={'extensionRecruiterStage'}>Extension Recruiter Stage</option>
                                                <option value={'extension'}>Extension</option>
                                                <option value={'status'}>Placement Status</option>
                                                <option value={'terminated'}>Terminated</option>
                                                <option value={'numberOfWeeksBooked'}>Number of Weeks Booked</option>
                                            </optgroup>
                                        </FormSelect>
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a selection condition.
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                    <FormSelect
                                            onChange={(e) => handleComparatorChange(e, index)}
                                            value={c.apiComparator}>
                                            <option value={'<'}>Less than</option>
                                            <option value={'<='}>Less than or equal to</option>
                                            <option value={'='}>Equals</option>
                                            <option value={'>='}>Greater than or equal to</option>
                                            <option value={'>'}>Greater than</option>
                                            <option value={'!='}>Does not equal</option>
                                        </FormSelect>
                                    </Col>
                                    <Col>
                                        { c.apiName === undefined ? null :
                                            <LastBox
                                                condition={c}
                                                handleApiValueChange={(e) => handleApiValueChange(e, index)}
                                                idx={index}
                                            />
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    ))}
                    <Row md={'auto'} className={'trigger-rows'}>
                        <span>
                            <Button size={'sm'} variant={'success'} onClick={props.addConditionCallback}>Add condition</Button>
                        </span>
                    </Row>
                    <Row className={'trigger-rows'}>
                        <small>Note: The message will be sent to any recipients that meet ALL of the above conditions.</small>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};