import './ReportView.css';
import {Col, FormControl, FormSelect, Row, Stack} from 'react-bootstrap';
import {BackButton} from '../BackButton/BackButton';
import {HorizontallyCenteredSpinner} from '../util/HorizontallyCenteredSpinner';

import React, {ChangeEvent, useState} from 'react';
import {DynamicWidthInput} from '../util/DynamicWidthInput';

interface Props {

}

export const ReportView = (props: Props) => {

    const [loading] = useState<boolean>(false);
    
    const [selectedJourney, setSelectedJourney] = useState<string>('Select a Journey');
    const [searchString, setSearchString] = useState<string>('');

    return (
        <div id="sms-container">
            <Stack gap={2}>
                <Row md={'auto'} className={'align-items-center'}>
                    <Col>
                        <BackButton/>
                    </Col>
                    <Col>
                        <h1 className={'max-header'}>JOURNEY REPORTS</h1>
                    </Col>
                </Row>
                <Row className='whitebox-reports' md={'auto'}>
                    <Col>
                        <label style={{margin: 0}}>Journey Name*</label>
                        <FormSelect
                            value={selectedJourney}
                            onChange={(e) => setSelectedJourney(e.target.value.toString())}
                        >
                            <option value={'undefined'}>Select a Journey</option>
                                <option value={'journey1'}>Journey #1</option>
                                <option value={'journey2'}>Journey #2</option>
                                <option value={'journey3'}>Journey #3</option>
                                <option value={'journey4'}>Journey #4</option>
                                <option value={'journey5'}>Journey #5</option>
                        </FormSelect>
 
                    </Col>
                    <Col md={{ offset: 3 }} >
                        <label style={{margin: 0}}>Search</label>
                        <DynamicWidthInput
                            value={searchString}
                            minWidth={300}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchString(e.target.value.toString())}
                        />
                        
                    </Col>
                    <Col>
                        <label style={{margin: 0}}>Start Date</label>
                        <FormControl
                            type={'date'}
                            as={'input'}
                        />
                    </Col>
                    <Col>
                        <label style={{margin: 0}}>End Date</label>
                        <FormControl
                            type={'date'}
                            as={'input'}
                        />
                    </Col>
                </Row>
                <Row>
                    {
                        loading ?
                            <HorizontallyCenteredSpinner showLoadingText={true}/>
                            :
                            <>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Journey Name</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Date Sent</th>
                                        <th>Time Sent</th>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    <tr>
                                        <td>Journey #1</td>
                                        <td>Todd</td>
                                        <td>Grunderson</td>
                                        <td>Jun 11, 2024</td>
                                        <td>12:02 PM</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="rectangle"></div>
                            </>
                    }
                </Row>
            </Stack>
        </div>
    );
};