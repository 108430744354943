import {Col, Container, Row} from 'react-bootstrap';

interface PermissionDeniedProps {
    type: 'app' | 'route';
}

const PermissionDenied = (props: PermissionDeniedProps) => {
    return (
        <Container fluid>
            <Row>
                <Col>
                    <p>You do not have the required permissions to access this {props.type === 'app' ? 'application' : 'screen'}.
                        If you believe this to be in error, please contact your system administrator.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default PermissionDenied;