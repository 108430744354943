import {FormCheck, FormControl, FormLabel} from 'react-bootstrap';
import {ChangeEvent} from 'react';
import './JourneyDetailView.css';

interface Props {
    dayName: string;
    startTime: string | undefined;
    endTime: string | undefined;
    active: boolean;
    disabled: boolean;
    onStartChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onEndChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onCheckedChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const DayTimeModule = (props: Props) => {


    return (
        <div className={`day-time-module ${props.disabled ? 'disabled' : ''}`}>
            <div className="day-time-module-top-row">
                <FormCheck
                    type={'checkbox'}
                    className={'day-time-module-checkbox'}
                    disabled={props.disabled}
                    checked={props.disabled ? false : props.active}
                    onChange={props.onCheckedChange}
                />
                <h2 className={'day-time-module-header'}>{props.dayName}</h2>
            </div>
            <div className="start-stop-dropdown-row">
                <div>
                    <FormLabel>Start*</FormLabel>
                    <FormControl
                        type={'time'}
                        value={props.startTime}
                        onChange={props.onStartChange}
                        disabled={props.disabled ? true : !props.active}
                    />
                </div>
                <div>
                    <FormLabel>Stop*</FormLabel>
                    <FormControl
                        type={'time'}
                        value={props.endTime}
                        onChange={props.onEndChange}
                        disabled={props.disabled ? true : !props.active}
                    />
                </div>
            </div>
        </div>
    );
};