import {Button, FormControl, Image, OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {COPY_JOURNEY, DELETE_JOURNEY, EDIT_JOURNEY, PAUSE_JOURNEY} from '../../util/constants';
import edit_journey_image from '../../assets/images/edit_journey.svg';
import copy_journey_image from '../../assets/images/copy_journey.svg';
import pause_journey_image from '../../assets/images/pause_journey.svg';
import delete_journey_image from '../../assets/images/delete_journey.svg';
import resume_journey_image from '../../assets/images/resume_journey.png';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {Journey} from '../../types/Journey';

interface Props {
    journeyGuid: string;
    journey: Journey;
    loadingClone: boolean;
    loadingPause: boolean;
    loadingDelete: boolean;
    updateStatusCallback: (guid: string) => void;
    cloneJourneyCallback: (guid: string) => void;
    deleteJourneyCallback: (guid: string) => void;
}

export const ButtonsBar = (props: Props) => {

    const navigate = useNavigate();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(false);

    const handleDeleteClose = () => setShowDeleteModal(false);
    const handleDeleteShow = () => setShowDeleteModal(true);

    return (
        <>
            <OverlayTrigger overlay={(props) => (<Tooltip {...props}>{EDIT_JOURNEY}</Tooltip>)} placement={'bottom'}>
              <span>
                  <Button variant={'link'} onClick={() => navigate(`/sms/journey/${props.journeyGuid}`)}>
                      <Image src={edit_journey_image}/>
                  </Button>
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={(props) => (<Tooltip {...props}>{COPY_JOURNEY}</Tooltip>)} placement={'bottom'}>
              <span className={'journey-button-span'}>
                  {
                      // eslint-disable-next-line no-constant-condition
                      props.loadingClone ?
                          <Spinner size={'sm'} as={'div'} className={'button-spinner'}/>
                          :
                          <Button variant={'link'} onClick={() => props.cloneJourneyCallback(props.journeyGuid)}>
                              <Image src={copy_journey_image}/>
                          </Button>
                  }
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={(injectedProps) => (<Tooltip {...injectedProps}>{props.journey.status === 'Active' ? PAUSE_JOURNEY : 'Resume Journey'}</Tooltip>)} placement={'bottom'}>
              <span className={'journey-button-span'}>
                  {
                      props.loadingPause ?
                          <Spinner size={'sm'} as={'div'} className={'button-spinner'}/>
                          :
                          <Button variant={'link'} onClick={() => props.updateStatusCallback(props.journeyGuid)}>
                              <Image src={props.journey.status === 'Active' ? pause_journey_image : resume_journey_image}/>
                          </Button>
                  }
              </span>
            </OverlayTrigger>
            <OverlayTrigger overlay={(props) => (<Tooltip {...props}>{DELETE_JOURNEY}</Tooltip>)} placement={'bottom'}>
              <span className={'journey-button-span'}>
                  {
                      props.loadingDelete ?
                          <Spinner size={'sm'} as={'div'} className={'button-spinner'}/>
                          :
                          <Button variant={'link'} onClick={() => handleDeleteShow()}>
                              <Image src={delete_journey_image}/>
                          </Button>
                  }
              </span>
            </OverlayTrigger>

            <Modal show={showDeleteModal} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Journey</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete this journey? If so, type the indicated journey name in the
                        field below:</p>
                    <code>{props.journey.journeyName}</code>
                    <FormControl as={'textarea'} rows={1}
                                 onChange={(e) => {
                                     if (e.target.value === props.journey.journeyName) {
                                         setDeleteButtonEnabled(true);
                                     } else {
                                         setDeleteButtonEnabled(false);
                                     }
                                 }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        setDeleteButtonEnabled(false);
                        handleDeleteClose();
                    }}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!deleteButtonEnabled} onClick={() => {
                        props.deleteJourneyCallback(props.journeyGuid);
                        handleDeleteClose();
                    }}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};