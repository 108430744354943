import {Col, Container, Row} from 'react-bootstrap';
import './Home.css';

export const Home = () => {
    return (
        <Container id={'home-container'}>
            <Row className={'align-items-center welcome-text'}>
                <Col md={'auto'}>
                    <h1>
                        Welcome to the Host Healthcare IT portal!
                    </h1>
                </Col>
            </Row>
        </Container>
    );
};