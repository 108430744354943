import axios, {AxiosInstance} from 'axios';
import {MSALData} from '../util/MSALData';
import {protectedResources} from '../util/authConfig';
import {EventMessage, EventType, IPublicClientApplication, SilentRequest} from '@azure/msal-browser';
import {apiEnvironment} from '../util/environment';
import {constructJourneyFromAPIResponse, constructJourneyListFromAPIResponse, Journey} from '../types/Journey';
import {User} from '../types/User';

let messagingApi: AxiosInstance;

export async function initialize() {

    const msalInstance = MSALData.getInstance().getMsalInstance();

    if (!msalInstance.getActiveAccount()) {

        msalInstance.addEventCallback(async (event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS) {
                messagingApi = await makeAxios(apiEnvironment.MESSAGING_API_BASE_URL, msalInstance);
            }
        });

    } else {
        messagingApi = await makeAxios(apiEnvironment.MESSAGING_API_BASE_URL, msalInstance);
    }
}

async function makeAxios(baseUrl: string, msalInstance: IPublicClientApplication) {

    const accessTokenReq: SilentRequest = {
        scopes: protectedResources.portal.scopes.user,
        account: msalInstance.getActiveAccount()!
    };

    const token = await msalInstance.acquireTokenSilent(accessTokenReq);

    const config = {
        baseURL: baseUrl,
        headers: {
            'Authorization': `Bearer ${token.accessToken}`
        }
    };

    return axios.create(config);

}

export async function fetchRecipient(recipientGuid: string) {
    return await messagingApi.get(`/recipients/${recipientGuid}`);
}

export async function fetchJourney(journeyGuid: string) {
    const response = await messagingApi.get(`/journeys/${journeyGuid}`);
    response.data = constructJourneyFromAPIResponse(response.data);
    return response;
}

export async function fetchJourneys() {
    const response = await messagingApi.get('/journeys/');
    response.data = constructJourneyListFromAPIResponse(response.data);
    return response;
}

export async function createJourney(journey: Journey) {
    return await messagingApi.post('/journeys/', journey);
}

export async function deleteJourney(journeyGuid: string) {
    return await messagingApi.delete(`/journeys/${journeyGuid}`);
}

export async function updateJourney(journey: Journey) {
    return await messagingApi.put(`/journeys/${journey.journeyGuid}`, journey);
}

export async function changeJourneyStatus(journeyGuid: string) {
    return await messagingApi.put(`/journeys/${journeyGuid}/status`);
}

export async function fetchUsers() {
    return await messagingApi.get('/users/');
}

export async function fetchUser(username: string) {
    return await messagingApi.get(`/users?username=${username}`);
}

export async function updateUser(user: User){
    return await messagingApi.put(`/users/${user.userGuid}`, user);
}

export async function createUser(user: User) {
    return await messagingApi.post('/users/', user);
}