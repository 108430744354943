import {IPublicClientApplication} from '@azure/msal-browser';

export class MSALData {

    private static instance: MSALData;

    private static msalInstance: IPublicClientApplication;

    private static userRoles: string[];

    private constructor() {
    }

    public static getInstance(): MSALData {
        if (!MSALData.instance) {
            MSALData.instance = new MSALData();
        }

        return MSALData.instance;

    }

    public getMsalInstance(): IPublicClientApplication {
        return MSALData.msalInstance;
    }

    public setMsalInstance(msalInstance: IPublicClientApplication) {
        MSALData.msalInstance = msalInstance;
    }

    public getUserRoles(): string[] {
        return MSALData.userRoles;
    }

    public setUserRoles(userRoles: string[]) {
        MSALData.userRoles = userRoles;
    }

}