import {Button} from 'react-bootstrap';
import {Circle} from '../Circle/Circle';
import arrow_left_short from '../../assets/images/arrow-left-short.svg';

import {useNavigate} from 'react-router-dom';

export const BackButton = () => {
    const navigation = useNavigate();

    return (
        <Button variant={'link'} onClick={() => navigation(-1)}>
            <Circle color={'blue'} diameter={'40px'} image={arrow_left_short} />
        </Button>
    );
};