import {Spinner} from 'react-bootstrap';

interface Props {
    showLoadingText?: boolean;
}

export const HorizontallyCenteredSpinner = (props: Props) => {
    return (
        <div style={{textAlign: 'center'}}>
            <Spinner animation={'border'} role={'status'} />
            {props.showLoadingText ? <p>Loading...</p> : <></>}
        </div>
    );
};