import {APIData} from '../types/APIData';

export const EDIT_JOURNEY = 'Edit Journey';
export const COPY_JOURNEY = 'Copy Journey';
export const PAUSE_JOURNEY = 'Pause Journey';
export const DELETE_JOURNEY = 'Delete Journey';


export const salesForceCandidateData: APIData[] = [
    {
      name: 'Candidate First Name',
      tokenString: '<candidate-first-name>'
    },

    {
        name: 'Candidate Status',
        tokenString: '<candidate-status>'
    },
    {
        name: 'Number of Placements',
        tokenString: '<number-of-placements>'
    },
    {
        name: 'Temp Address Last Updated Date',
        tokenString: '<temp-address-last-update>'
    },
    {
        name: 'Temp Type',
        tokenString: '<temp-type>'
    },
];

export const salesforceJobData: APIData[] = [
    {
        name: 'Placement Start Date',
        tokenString: '<placement-start>'
    },
    {
      name: 'Placement End Date',
      tokenString: '<placement-end>'
    },
    {
        name: 'Extension Recruiter Stage',
        tokenString: '<extension-recruiter-stage>'
    },
    {
        name: 'Extension',
        tokenString: '<extension>'
    },
    {
        name: 'Status',
        tokenString: '<status>'
    },
    {
        name: 'Terminated',
        tokenString: '<terminated>'
    },
    {
        name: 'Number of Weeks Booked',
        tokenString: '<weeks-booked>'
    },
];