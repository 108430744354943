
interface CirlceProps {
    color: string;
    diameter: string;
    image?: string;
}

export const Circle = (props: CirlceProps) => {
    return (
        <div className="circle" style={{backgroundColor: props.color, width: props.diameter, height: props.diameter}}>
            { props.image != null ? <img src={props.image} alt={'image'} /> : null }
        </div>
    );
};