import {Card, CardBody, CardText, CardTitle, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaste} from '@fortawesome/free-solid-svg-icons';

interface APIDataCardProps {
    name: string;
    tokenString: string;
    copyTokenCallback: (token: string) => void;
}

export const APIDataCard = (props: APIDataCardProps) => {

    return (
        <Card className={'api-card'}>
            <CardBody>
                <CardTitle>{props.name}</CardTitle>
                <CardText>
                    Token: {props.tokenString}
                    <OverlayTrigger overlay={(props) => (<Tooltip {...props}>Copy token to message body</Tooltip>)}>
                        <FontAwesomeIcon
                            className={'copy-icon'}
                            onClick={() => props.copyTokenCallback(props.tokenString)}
                            style={{marginLeft:'10px'}} icon={faPaste}
                        />
                    </OverlayTrigger>
                </CardText>
            </CardBody>
        </Card>
    );

};