import {DayTimeModule} from './DayTimeModule';
import {Journey} from '../../types/Journey';
import {ChangeEvent, Dispatch, SetStateAction} from 'react';
import {DayData} from '../../types/DayData';

interface DailyFrequencySectionProps {
    journey: Journey;
    setJourney: Dispatch<SetStateAction<Journey>>;
    dayData: DayData[];
    setDayData: Dispatch<SetStateAction<DayData[]>>;
}

export const DailyFrequencySection = (props: DailyFrequencySectionProps) => {

    const getStartTime = (day: DayData): string | undefined => {
        switch (day.dayName){
            case 'MONDAY':
                return props.journey.mondayStartTime;
            case 'TUESDAY':
                return props.journey.tuesdayStartTime;
            case 'WEDNESDAY':
                return props.journey.wednesdayStartTime;
            case 'THURSDAY':
                return props.journey.thursdayStartTime;
            case 'FRIDAY':
                return props.journey.fridayStartTime;
            case 'SATURDAY':
                return props.journey.saturdayStartTime;
            case 'SUNDAY':
                return props.journey.sundayStartTime;
        }
    };

    const getEndTime = (day: DayData): string | undefined => {
        switch (day.dayName){
            case 'MONDAY':
                return props.journey.mondayEndTime;
            case 'TUESDAY':
                return props.journey.tuesdayEndTime;
            case 'WEDNESDAY':
                return props.journey.wednesdayEndTime;
            case 'THURSDAY':
                return props.journey.thursdayEndTime;
            case 'FRIDAY':
                return props.journey.fridayEndTime;
            case 'SATURDAY':
                return props.journey.saturdayEndTime;
            case 'SUNDAY':
                return props.journey.sundayEndTime;
        }
    };

    const handleStartTimeChange = (event: ChangeEvent<HTMLInputElement>, day: DayData) => {
        const newJourney = {...props.journey};
        switch (day.dayName){
            case 'MONDAY':
                newJourney.mondayStartTime = `${event.target.value}:00`;
                break;
            case 'TUESDAY':
                newJourney.tuesdayStartTime = `${event.target.value}:00`;
                break;
            case 'WEDNESDAY':
                newJourney.wednesdayStartTime = `${event.target.value}:00`;
                break;
            case 'THURSDAY':
                newJourney.thursdayStartTime = `${event.target.value}:00`;
                break;
            case 'FRIDAY':
                newJourney.fridayStartTime = `${event.target.value}:00`;
                break;
            case 'SATURDAY':
                newJourney.saturdayStartTime = `${event.target.value}:00`;
                break;
            case 'SUNDAY':
                newJourney.sundayStartTime = `${event.target.value}:00`;
                break;
        }
        props.setJourney(newJourney);
    };

    const handleEndTimeChange = (event: ChangeEvent<HTMLInputElement>, day: DayData) => {
        const newJourney = {...props.journey};
        switch (day.dayName){
            case 'MONDAY':
                newJourney.mondayEndTime = `${event.target.value}:00`;
                break;
            case 'TUESDAY':
                newJourney.tuesdayEndTime = `${event.target.value}:00`;
                break;
            case 'WEDNESDAY':
                newJourney.wednesdayEndTime = `${event.target.value}:00`;
                break;
            case 'THURSDAY':
                newJourney.thursdayEndTime = `${event.target.value}:00`;
                break;
            case 'FRIDAY':
                newJourney.fridayEndTime = `${event.target.value}:00`;
                break;
            case 'SATURDAY':
                newJourney.saturdayEndTime = `${event.target.value}:00`;
                break;
            case 'SUNDAY':
                newJourney.sundayEndTime = `${event.target.value}:00`;
                break;
        }
        props.setJourney(newJourney);
    };

    const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>, day: DayData) => {
        const newDays = [...props.dayData];
        const newJourney = {...props.journey};
        const dayToChange = newDays.find(d => d.dayName === day.dayName);
        if (dayToChange) {
            dayToChange.active = !dayToChange.active;
            props.setDayData(newDays);
            switch (day.dayName) {
                case 'MONDAY':
                    newJourney.mondayStartTime = '00:00:00';
                    newJourney.mondayEndTime = '00:00:00';
                    break;
                case 'TUESDAY':
                    newJourney.tuesdayStartTime = '00:00:00';
                    newJourney.tuesdayEndTime = '00:00:00';
                    break;
                case 'WEDNESDAY':
                    newJourney.wednesdayStartTime = '00:00:00';
                    newJourney.wednesdayEndTime = '00:00:00';
                    break;
                case 'THURSDAY':
                    newJourney.thursdayStartTime = '00:00:00';
                    newJourney.thursdayEndTime = '00:00:00';
                    break;
                case 'FRIDAY':
                    newJourney.fridayStartTime = '00:00:00';
                    newJourney.fridayEndTime = '00:00:00';
                    break;
                case 'SATURDAY':
                    newJourney.saturdayStartTime = '00:00:00';
                    newJourney.saturdayEndTime = '00:00:00';
                    break;
                case 'SUNDAY':
                    newJourney.sundayStartTime = '00:00:00';
                    newJourney.sundayEndTime = '00:00:00';
                    break;
            }
            props.setJourney(newJourney);
        }
    };

    return (
        <>
            <h2>DAILY FREQUENCY</h2>
            <div className="daily-frequency-container">
                {
                    props.dayData.map((d) => {
                        return (
                            <DayTimeModule
                                dayName={d.dayName}
                                startTime={getStartTime(d)}
                                endTime={getEndTime(d)}
                                active={d.active}
                                disabled={props.journey.deferToWeekend && (d.dayName === 'SATURDAY' || d.dayName === 'SUNDAY')}
                                onStartChange={(e) => handleStartTimeChange(e, d)}
                                onEndChange={(e) => handleEndTimeChange(e, d)}
                                onCheckedChange={(e) => handleCheckedChange(e, d)}
                            />
                        );
                    })
                }
            </div>
        </>
    );
};