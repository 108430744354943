import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {AccountInfo, EventMessage, EventType, IdTokenClaims, IPublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import {BrowserRouter, Route} from 'react-router-dom';
import Header from './components/Header/Header';
import AppContainer from './components/AppContainer/AppContainer';
import {SmsView} from './components/SmsView/SmsView';
import {Routes} from 'react-router';
import {appRoles, msalConfig} from './util/authConfig';
import PermissionDenied from './components/PermissionDenied/PermissionDenied';
import {JourneyDetailView} from './components/JourneyDetailView/JourneyDetailView';
import {MSALData} from './util/MSALData';
import {Home} from './components/Home/Home';
import {AdminPanel} from './components/AdminPanel/AdminPanel';
import {ReportView} from './components/ReportView/ReportView';

interface AppProps {
    msalInstance: IPublicClientApplication;
}

function App({msalInstance}: AppProps) {

    const [roles, setRoles] = useState<string[]>();
    
    // inter-component state
    const [journeyCreationSuccess, setJourneyCreationSuccess] = useState(false);
    const [journeyUpdateSuccess, setJourneyUpdateSuccess] = useState(false);

    const extractRolesFromAccount = (account: AccountInfo) => {
        if (account.tenantId === msalConfig.auth.tenantId) {
            const idTokenClaims = account.idTokenClaims as IdTokenClaims;
            if (idTokenClaims && idTokenClaims.aud === msalConfig.auth.clientId && idTokenClaims['roles']) {
                const roles = idTokenClaims['roles'];
                setRoles(roles);
                MSALData.getInstance().setUserRoles(roles);
            }
        }
    };

    useEffect(() => {

        if (msalInstance.getActiveAccount()) {

            extractRolesFromAccount(msalInstance.getActiveAccount()!);

        } else {

            msalInstance.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS) {
                    if (msalInstance.getActiveAccount()) {
                        const activeAccount = msalInstance.getActiveAccount();
                        extractRolesFromAccount(activeAccount!);
                    }
                }
            });

        }

    }, [msalInstance]);

    // TODO: need to block off routes based on access from the user table
    return (
        <MsalProvider instance={msalInstance}>
            {roles?.includes(appRoles.User) ?
                <>
                    <BrowserRouter>
                        <Header/>
                        <Routes>
                            <Route path={'/'} element={<AppContainer/>}>
                                <Route index element={<Home/>} />
                                <Route path={'/sms'} element={<SmsView journeyCreationSuccess={journeyCreationSuccess} journeyUpdateSuccess={journeyUpdateSuccess} />} />
                                <Route path={'/sms/journey/:guid?'} element={<JourneyDetailView setJourneyCreationSuccess={setJourneyCreationSuccess} setJourneyUpdateSuccess={setJourneyUpdateSuccess} />}/>
                                <Route path={'/sms/reports'} element={<ReportView />} />
                                {roles?.includes(appRoles.Admin) ?
                                    <Route path={'/admin/'} element={<AdminPanel/>} />
                                    :
                                    <></>
                                }
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </> :
                <PermissionDenied type={'app'}/>
            }
        </MsalProvider>
    );
}

/*

 */

export default App;
