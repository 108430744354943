export const authEnvironment = {
    AD_TENANT_ID: process.env.REACT_APP_AD_TENANT_ID,
    AD_CLIENT_ID: process.env.REACT_APP_AD_CLIENT_ID,
    AD_AUTHORITY: `https://login.microsoftonline.com/${process.env.REACT_APP_AD_TENANT_ID}`,
    AD_REDIRECT_URI: process.env.REACT_APP_AD_REDIRECT_URI,
    AD_USER_SCOPE: process.env.REACT_APP_AD_USER_SCOPE,
    AD_DEBUG: process.env.NODE_ENV !== 'production' &&  process.env.REACT_APP_AD_DEBUG === 'true'
};

export const apiEnvironment = {
    MESSAGING_API_BASE_URL: process.env.REACT_APP_MESSAGING_API!
};

