import {Toast, ToastBody, ToastHeader} from 'react-bootstrap';
import './OverlayToast.css';

interface Props {
    show: boolean;
    headerText: string;
    bodyText: string;
    bg?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
}

export const OverlayToast = (props: Props) => {
    return (
      <Toast className={'overlay-toast'} show={props.show} bg={props.bg}>
          <ToastHeader closeButton={false}>
              <strong>{props.headerText}</strong>
          </ToastHeader>
          <ToastBody className={'overlay-toast-body'}>
              {props.bodyText}
          </ToastBody>
      </Toast>
    );
};