import {User} from '../../types/User';
import {ChangeEvent, useState} from 'react';
import {Button, FormSelect} from 'react-bootstrap';
import {updateUser} from '../../api';

interface Props {
    user: User;
    showToastCallback: (variant: 'success' | 'danger') => void;
}

export const UserListItem = (props: Props) => {

    const [roleChanged, setRoleChanged] = useState<boolean>(false);
    const [role, setRole] = useState<string>(props.user.role);

    const handleRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setRole(event.target.value);

        if (event.target.value === props.user.role && roleChanged) {
            setRoleChanged(false);
        }
        else {
            setRoleChanged(true);
        }
    };

    const handleRoleSave = async () => {
        const newUser: User = {
          ...props.user,
          role: role as 'Admin' | 'SmsRead' | 'SmsWrite'
        };
        const response = await updateUser(newUser);

        if (response.status !== 200 && response.status !== 204) {
            props.showToastCallback('danger');
        }
        else {
            props.showToastCallback('success');
            setRoleChanged(false);
            props.user.role = newUser.role;
        }

    };

    return (
        <>
            <td>{props.user.name}</td>
            <td>{props.user.entraUsername}</td>
            <td className={'role-cell'}>
                <FormSelect value={role} onChange={handleRoleChange}>
                    <option value={'Admin'}>Admin</option>
                    <option value={'SmsRead'}>SMS Read</option>
                    <option value={'SmsWrite'}>SMS Write</option>
                </FormSelect>
                {roleChanged ? <Button onClick={() => handleRoleSave()} className={'save-button'} variant={'success'}>Save</Button> : <></>}
            </td>
        </>
    );
};