/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthenticationResult, EventMessage, EventType, PublicClientApplication} from '@azure/msal-browser';

import {msalConfig, protectedResources} from './util/authConfig';
import {loginRequest} from './util/authConfig';
import {MSALData} from './util/MSALData';
import {createUser, fetchUser, initialize} from './api';
import {WebState} from './util/WebState';
import {User} from './types/User';
import {OverlayToast} from './components/OverlayToast/OverlayToast';
import {AxiosError} from 'axios';
import {authEnvironment} from './util/environment';

const msalData = MSALData.getInstance();
const webState = WebState.getInstance();

let showLoginError = false;

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();
const accounts = msalInstance.getAllAccounts();

accounts.forEach((account) => {
   if (account?.tenantId === msalConfig.auth.tenantId) {
       msalInstance.setActiveAccount(account);
       msalData.setMsalInstance(msalInstance);
       const fetchUserData = async () => {
           await initialize();

           try {
               const response = await fetchUser(account.username);

               if (response.status === 200) {
                   const user = response.data as User;
                   webState.setUser(user);
               }
           } catch (err: any) {
               if ((err as AxiosError).response!.status === 404) {
                   const newUser: User = {
                       entraUsername: account.username,
                       name: account.name ? account.name : 'No Name',
                       role: 'SmsRead'
                   };

                   try {
                       const response = await createUser(newUser);
                       if (response.status !== 200) {
                           showLoginError = true;
                       }
                   } catch (err) {
                       showLoginError = true;
                   }

               }
               else {
                   showLoginError = true;
               }
           }

       };

       fetchUserData();
   }
});

function loginRedirect() {
  try {
      msalInstance.loginRedirect(loginRequest);
  } catch (err) {
      if (authEnvironment.AD_DEBUG) {
          console.log(err);
      }
  }
}

msalInstance.addEventCallback((event: EventMessage) => {

    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {

        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
        msalData.setMsalInstance(msalInstance);
        initialize();

    } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {

        if (event.error?.name === 'InteractionRequiredAuthError' && window.location.pathname.startsWith(protectedResources.portal.path)) {
            loginRedirect();
        } else {
            if (authEnvironment.AD_DEBUG) {
                console.log('ACQUIRE_TOKEN_FAILURE');
            }
        }

    } else if (event.eventType === EventType.LOGIN_FAILURE) {

        if (event.error?.name === 'BrowserAuthError' && window.location.pathname.startsWith(protectedResources.portal.path)) {
            loginRedirect();
        } else {
            if (authEnvironment.AD_DEBUG) {
                console.log('LOGIN FAILURE');
            }
        }

    }

});

msalInstance.handleRedirectPromise().then(() => {
    if (window.location.pathname.startsWith(protectedResources.portal.path)) {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            loginRedirect();
        }
    }
}).catch((err) => {
    if (authEnvironment.AD_DEBUG) {
        console.log(err);
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App msalInstance={msalInstance} />
    <OverlayToast show={showLoginError} headerText={'Error'} bodyText={'There was an error while logging you in. Please contact support.'} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
